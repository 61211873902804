<template>
  <div>
    <retryable-error v-if="hasLoadingError" text="Error loading dataset groups." @retry="fetchDatasetGroups"></retryable-error>
    <loading v-else-if="datasetGroups === null" text="Loading dataset groups..."></loading>
    <div v-else-if="datasetGroups.length === 0" class="flex justify-center w-full text-gray-500 mt-8">
      <div class="flex flex-col items-center max-w-[50%]">
        <i class="fas fa-layer-group text-8xl" />
        <div class="font-bold text-4xl">No Dataset Groups</div>
        <div class="text-2xl text-center">Looks like you have not yet created any Dataset Groups. Dataset Groups allow you to perform a single search across multiple Datasets.</div>
        <div class="btn btn-theme w-full" @click="createGroup"><i class="fas fa-plus mr-1" />Create Group</div>
      </div>
    </div>
    <div v-else class="flex w-full gap-2">
      <v-select ref="datasetGroupSelector" class="flex-1" :options="datasetGroups" :get-option-label="item => item.name" :selectable="item => hasAccessToDatasetGroup(item)" v-model="selectedGroup" placeholder="type or select a dataset group" :append-to-body="true">
        <template #option="item">
          <div class="flex w-full items-center">
            <i v-if="!hasAccessToDatasetGroup(item)" class="fas fa-warning text-amber-500 mr-1" v-tooltip="'Your account no longer has access to this Dataset Grouping, because you have lost access to 1 or more Datasets in this group.'" />
            <div class="flex flex-col truncate flex-1">
              <div class="truncate">{{ item.name }}</div>
              <div class="text-sm truncate">{{ item.datasets.map(d => d.datasetId ).join(', ') }}</div>
            </div>
            <div class="flex gap-2 justify-self-end">
              <i class="cursor-pointer fas fa-trash text-red-600 hover:text-red-200" @click.stop="deleteGroup(item)" />
              <i class="cursor-pointer fas fa-pencil text-theme-500 hover:text-theme-100" @click.stop="editGroup(item)" />
            </div>
          </div>
        </template>
      </v-select>
      <div class="btn btn-theme" @click="createGroup"><i class="fas fa-plus mr-1" />Create Group</div>
    </div>
    <modal v-if="groupEditing">
      <template v-slot:header>
        <div class="flex justify-between w-full">
          <h1 class="text-2xl">{{ groupEditing.id === null ? 'New Dataset Group' : 'Edit Dataset Group' }}</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="w-full flex flex-col gap-2">
          <div class="flex flex-col w-full">
            <strong>Group Name</strong>
            <input class="form-control" v-model="groupEditing.name" :disabled="groupEditing.id !== null" />
          </div>
          <div class="flex flex-col w-full">
            <strong>Group Description - Optional</strong>
            <textarea class="form-control" v-model="groupEditing.description"></textarea>
          </div>
          <div class="flex flex-col w-full">
            <strong>Datasets</strong>
            <div class="text-sm">Choose at least 2 Datasets to group together.</div>
            <simple-multi-dataset-selector v-model="groupEditing.datasets"></simple-multi-dataset-selector>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" @click="groupEditing = null"><i class="fas fa-xmark mr-1" />Close</button>
        <button class="btn btn-theme" @click="saveGroupEditing" :disabled="!isEditingGroupValid"><i class="fas fa-save mr-1" />Save</button>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import RetryableError from '@/components/RetryableError'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import SimpleMultiDatasetSelector from '@/components/SimpleMultiDatasetSelector'

export default {
  name: 'dataset-groupings',
  mixins: [
    FullScreenLoadingMixin
  ],
  props: {
    modelValue: { type: Object, required: false, default: null }
  },
  emits: ['update:modelValue'],
  components: {
    RetryableError,
    Loading,
    Modal,
    SimpleMultiDatasetSelector
  },
  data () {
    return {
      selectedGroup: null,
      hasLoadingError: false,
      groupEditing: null,
      datasetAccess: null
    }
  },
  computed: {
    isEditingGroupValid () {
      if (this.groupEditing === null) return false
      if (this.groupEditing.name === null || this.groupEditing.name.trim().length === 0) return false
      if (this.groupEditing.datasets === null || this.groupEditing.datasets.length < 2) return false
      return true
    },
    datasetGroups () {
      return this.$store.state.datasetGroups
    }
  },
  watch: {
    modelValue () {
      this.setSelectedGroup()
    },
    selectedGroup () {
      if (this.selectedGroup === null) {
        this.$emit('update:modelValue', null)
        return
      }

      const model = {
        id: this.selectedGroup.id,
        label: this.selectedGroup.name,
        model: {
          category: {
            id: this.selectedGroup.userId,
            description: this.selectedGroup.datasets.map(d => d.datasetId).join(', ')
          },
          dataset: {
            id: this.selectedGroup.name,
            description: this.selectedGroup.description,
            supportsMapResults: false
          }
        }
      }

      this.$emit('update:modelValue', model)
    }
  },
  methods: {
    hasAccessToDatasetGroup (datasetGroup) {
      for (const dataset of datasetGroup.datasets) {
        if (!this.hasDatasetAccess(dataset)) return false
      }
      return true
    },
    hasDatasetAccess (datasetRepresentation) {
      // if we haven't cached the datsets, assume they have access
      if (this.datasetAccess === null) return true

      for (const category of this.datasetAccess) {
        if (category.id === datasetRepresentation.datasetCategory) {
          return _.some(category.datasets, ['id', datasetRepresentation.datasetId])
        }
      }

      return false
    },
    async saveGroupEditing () {
      try {
        this.showLoading('Saving Dataset Grouping...', null)
        const isEditing = this.groupEditing.id !== null
        const action = isEditing ? 'editDatasetGroup' : 'createDatasetGroup'
        await this.$store.dispatch(action, this.groupEditing)
        await this.fetchDatasetGroups()
        this.groupEditing = null
        if (isEditing) {
          this.selectedGroup = null
          this.$store.commit('clearSourceDescriptions')
        }
        this.hideLoading()
        this.showSuccess('Dataset Group Saved')
      } catch (error) {
        console.error('Error saving dataset group', error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'Save Failed',
          text: 'An unknown error occurred while saving the Dataset Group. The Grouping you are creating may be invalid. All Datasets in the grouping must contain at least 1 required field to search on.',
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        })
      }
    },
    createGroup () {
      this.groupEditing = {
        id: null,
        name: '',
        description: '',
        datasets: []
      }
    },
    editGroup (group) {
      this.groupEditing = JSON.parse(JSON.stringify(group))
      this.$refs.datasetGroupSelector.closeSearchOptions()
    },
    async deleteGroup (group) {
      this.$refs.datasetGroupSelector.closeSearchOptions()
      const result = await this.$swal({
        icon: 'question',
        title: 'Delete Dataset Group?',
        text: `Are you sure you want to delete the Dataset Group "${group.name}"? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        allowOutsideClick: false,
        backdrop: true,
        allowEscapeKey: false
      })
      if (!result.isConfirmed) return

      try {
        this.showLoading('Deleting Dataset Grouping...', null)
        await this.$store.dispatch('deleteDatasetGroup', group.name)
        await this.fetchDatasetGroups()
        this.hideLoading()
        this.showSuccess('Dataset Group Deleted')
      } catch (error) {
        console.error('Error deleting dataset grouping', error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'Delete Failed',
          text: 'An unknown error occurred while deleting the Dataset Group. Please try again.',
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        })
      }
    },
    async fetchDatasetGroups () {
      this.hasLoadingError = false
      this.$store.commit('setDatasetGroups', null)
      this.datasetAccess = null

      try {
        const response = await this.$store.dispatch('fetchDatasetGroups')
        const groups = response.data
        for (const group of groups) {
          group.datasets = _.sortBy(group.datasets, 'datasetId')
        }
        this.$store.commit('setDatasetGroups', response.data)
        this.setSelectedGroup()
      } catch (error) {
        console.error('Error fetching dataset groups', error)
        this.hasLoadingError = true
      }
    },
    async fetchDatasetAccess () {
      this.datasetAccess = null
      try {
        this.datasetAccess = (await this.$store.dispatch('fetchUnifiedDatasets')).data
      } catch (error) {
        console.error('Error loading dataset access')
      }
    },
    setSelectedGroup () {
      if (this.modelValue === null) {
        this.selectedGroup = null
        return
      }

      if (this.datasetGroups === null) return

      const targetGroup = _.find(this.datasetGroups, { id: this.modelValue.id })
      if (typeof targetGroup !== 'undefined') {
        this.selectedGroup = targetGroup
      } else {
        this.selectedGroup = null
      }
    }
  },
  mounted () {
    this.fetchDatasetAccess()
    this.setSelectedGroup()
    if (this.datasetGroups === null) {
      this.fetchDatasetGroups()
    }
  }
}
</script>
