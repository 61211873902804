<script>
export default {
  name: 'full-screen-loading-mixin',
  methods: {
    showLoading (title, text) {
      const self = this
      this.$swal.fire({
        title,
        text,
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          self.$swal.showLoading()
        }
      })
    },
    hideLoading () {
      this.$swal.hideLoading()
      this.$swal.close()
    },
    showSuccess (title) {
      this.$swal({
        title,
        icon: 'success',
        allowOutsideClick: true,
        backdrop: true,
        allowEscapeKey: false,
        showConfirmButton: false,
        timer: 2000
      })
    }
  }
}
</script>
