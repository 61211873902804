<template>
  <div>
    <entity-editor
      ref="userEntityEditor"
      id-column="id"
      entity-type-display="User"
      entity-type="users"
      sort-column="username"
      :filter-columns="['username', 'email', 'firstName', 'lastName']"
      :custom-editor="true"
      :columns="userColumns"
      :disabled-actions="['import', 'export']"
      @new-entity="onNewEntity"
      @copy-entity="onCopyEntity"
      @edit-entity="onEditEntity">
    </entity-editor>

    <modal v-if="userEditing" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">{{ userEditing.id === null ? 'Create' : 'Edit' }} User</h1>
      </template>
      <template v-slot:body>
        <user-editor v-model="userEditing"></user-editor>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" href="#" @click="userEditing = null"><i class="fas fa-ban" /> Cancel</button>
        <button class="btn btn-theme" :disabled="!isUserValid" @click="saveUser"><i class="fas fa-save" /> Save</button>
      </template>
    </modal>
  </div>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import Modal from '@/components/Modal'
import UserEditor from '@/components/admin/UserEditor'

export default {
  name: 'user-settings',
  mixins: [
    FullScreenLoadingMixin
  ],
  components: {
    EntityEditor,
    Modal,
    UserEditor
  },
  data () {
    return {
      userEditing: null,
      userColumns: [
        { field: 'username', display: 'Username' },
        { field: 'email', display: 'Email' },
        { field: 'firstName', display: 'First Name' },
        { field: 'lastName', display: 'Last Name' }
      ]
    }
  },
  computed: {
    isUserValid () {
      if (this.userEditing === null) return false
      if (this.userEditing.username.length < 2) return false
      if (this.userEditing.email !== null && this.userEditing.email.length < 3) return false
      if (this.userEditing.firstName !== null && this.userEditing.firstName.length < 1) return false
      if (this.userEditing.lastName !== null && this.userEditing.lastName.length < 1) return false

      if (this.userEditing.password.setPassword) {
        if (this.userEditing.password.value.length < 4) return false
      }

      return true
    }
  },
  methods: {
    onNewEntity () {
      this.userEditing = {
        id: null,
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        password: {
          setPassword: true,
          value: '',
          requirePasswordChange: true
        },
        groups: [],
        roles: [],
        lockStatus: null
      }
    },
    async onCopyEntity (userToCopy) {
      // copying a user should only copy their groups/roles
      this.showLoading('Loading User', null)
      const groupsPromise = this.$store.dispatch('getUserGroups', userToCopy.id)
      const rolesPromise = this.$store.dispatch('getUserRoles', userToCopy.id)
      const promiseValues = await Promise.all([groupsPromise, rolesPromise])

      const groupsResponse = promiseValues[0]
      const rolesResponse = promiseValues[1]

      this.userEditing = {
        id: null,
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        password: {
          setPassword: true,
          value: '',
          requirePasswordChange: true
        },
        groups: groupsResponse.data,
        roles: rolesResponse.data,
        lockStatus: null
      }

      this.hideLoading()
    },
    async onEditEntity (userToEdit) {
      this.showLoading('Loading User', null)
      const groupsPromise = this.$store.dispatch('getUserGroups', userToEdit.id)
      const rolesPromise = this.$store.dispatch('getUserRoles', userToEdit.id)
      const lockPromise = this.$store.dispatch('getUserLockStatus', userToEdit.id)
      const promiseValues = await Promise.all([groupsPromise, rolesPromise, lockPromise])

      const groupsResponse = promiseValues[0]
      const rolesResponse = promiseValues[1]
      const lockResponse = promiseValues[2]

      this.userEditing = {
        id: userToEdit.id,
        username: userToEdit.username,
        email: userToEdit.email,
        firstName: userToEdit.firstName,
        lastName: userToEdit.lastName,
        password: {
          setPassword: false,
          value: '',
          requirePasswordChange: true
        },
        groups: groupsResponse.data,
        roles: rolesResponse.data,
        lockStatus: lockResponse.data
      }

      this.hideLoading()
    },
    async saveUser () {
      try {
        this.showLoading('Saving User', null)
        const saveAction = this.userEditing.id === null ? 'saveAdminEntityNew' : 'saveAdminEntityEdit'
        await this.$store.dispatch(saveAction, { type: 'users', entity: this.userEditing })

        this.$swal.fire({
          icon: 'success',
          title: 'User Saved',
          text: 'The user was successfully saved.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.userEditing = null
        this.$refs.userEntityEditor.loadEntities()
      } catch (error) {
        console.error('Error saving user', error)
        const serverErrors = error?.response?.data?._embedded?.errors?.map(e => e.message) ?? []
        this.$swal.fire({
          icon: 'error',
          title: 'User Save Failed',
          text: `Error saving the user.\n\n${serverErrors.join('/n/n')}\n\nCheck the browser's console for errors.`,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    }
  }
}
</script>
