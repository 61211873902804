<template>
  <div>
    <entity-editor
      ref="groupEntityEditor"
      entity-type-display="Groups"
      entity-type="groups"
      sort-column="name"
      :filter-columns="['name']"
      :custom-editor="true"
      :disabled-actions="['disable', 'export', 'import']"
      :columns="groupColumns"
      @new-entity="onNewEntity"
      @copy-entity="onCopyEntity"
      @edit-entity="onEditEntity">
    </entity-editor>

    <modal v-if="groupEditing" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">{{ groupEditing.id === null ? 'Create' : 'Edit' }} Group</h1>
      </template>
      <template v-slot:body>
        <div class="flex flex-col">
          <div>Group Name</div>
          <input class="form-control" placeholder="group name" v-model="groupEditing.name" />
        </div>
        <div class="flex flex-col">
      <div>Roles</div>
        <small>Members of this group will inherit these roles.</small>
        <v-select :options="groupEditing.availableRoles" v-model="groupEditing.roles" :multiple="true" placeholder="select the roles for this group" :append-to-body="true"></v-select>
      </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" href="#" @click="groupEditing = null"><i class="fas fa-ban" /> Cancel</button>
        <button class="btn btn-theme" :disabled="!isGroupValid" @click="saveGroup"><i class="fas fa-save" /> Save</button>
      </template>
    </modal>
  </div>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import Modal from '@/components/Modal'

export default {
  name: 'group-settings',
  mixins: [
    FullScreenLoadingMixin
  ],
  components: {
    EntityEditor,
    Modal
  },
  data () {
    return {
      groupEditing: null,
      groupColumns: [
        { field: 'name', display: 'Group Name' },
        { field: 'realmRoles', display: 'Roles', renderer: roles => roles.join(', ') }
      ]
    }
  },
  computed: {
    isGroupValid () {
      return true
    }
  },
  methods: {
    showError (title, text) {
      this.$swal.fire({
        title,
        text,
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true
      })
    },
    async saveGroup () {
      try {
        this.showLoading('Saving Group', null)
        const saveAction = this.groupEditing.id === null ? 'saveAdminEntityNew' : 'saveAdminEntityEdit'
        await this.$store.dispatch(saveAction, { type: 'groups', entity: this.groupEditing })

        this.$swal.fire({
          icon: 'success',
          title: 'Group Saved',
          text: 'The group was successfully saved.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true
        })
        this.groupEditing = null
        this.$refs.groupEntityEditor.loadEntities()
      } catch (error) {
        console.error('Error saving group', error)
        const serverErrors = error?.response?.data?._embedded?.errors?.map(e => e.message) ?? []
        this.showError('Group Save Failed', `Error saving the group.\n\n${serverErrors.join('/n/n')}\n\nCheck the browser's console for errors.`)
      }
    },
    async fetchRoles () {
      this.showLoading('Loading Roles', null)
      try {
        const response = await this.$store.dispatch('fetchAdminEntities', 'roles')
        return response.data.map(role => role.name)
      } catch (error) {
        console.error('An error occurred while fetching roles, cannot continue.', error)
        this.showError('Error Loading Roles', 'An error occurred lodaing the roles and the Group editor cannot be shown. Please try again.')
        return null
      } finally {
        this.hideLoading()
      }
    },
    async onNewEntity () {
      const availableRoles = await this.fetchRoles()
      if (availableRoles === null) {
        return
      }

      this.groupEditing = {
        availableRoles,
        id: null,
        name: '',
        roles: []
      }
    },
    async onEditEntity (groupToEdit) {
      const availableRoles = await this.fetchRoles()
      if (availableRoles === null) {
        return
      }

      this.groupEditing = {
        availableRoles,
        id: groupToEdit.id,
        name: groupToEdit.name,
        roles: groupToEdit.realmRoles
      }
    },
    async onCopyEntity (groupToCopy) {
      const availableRoles = await this.fetchRoles()
      if (availableRoles === null) {
        return
      }

      this.groupEditing = {
        availableRoles,
        id: null,
        name: '',
        roles: groupToCopy.realmRoles
      }
    }
  }
}
</script>
