<template>
  <div class="w-full">
    <retryable-error v-if="errorLoading" text="Error Loading Dataset Schema" @retry="fetchSchema"></retryable-error>
    <loading v-else-if="schema === null"  text="Loading..."></loading>
    <div v-else class="flex flex-col w-full gap-1 bg-white">
      <div :class="['flex flex-col bg-theme-500 text-white p-2 gap-1 sticky', { 'top-6': isFullscreen && hasInformationBanner }, { 'top-0': !isFullscreen || !hasInformationBanner }]">
        <div class="flex flex-col gap-1 md:flex-row justify-between">
          <h1 class="text-2xl font-extrabold">{{ schema.dataset.datasetId }}</h1>
          <div class="flex gap-2 text-xs items-center" v-if="schema.containsLocationData || schema.supportsMapResults">
            <div v-if="schema.supportsMapResults" class="rounded-xl bg-orange-600 text-white px-2 py-1"><i class="fas fa-globe mr-1" />Map View</div>
            <div v-if="schema.containsLocationData" class="rounded-xl bg-green-700 text-white px-2 py-1"><i class="fas fa-location-dot mr-1" />Location</div>
          </div>
          <inline-dropdown v-if="editOptions.length > 1 && editModel === null && adminEditMode === false" :options="editOptions" button-title="Edit"></inline-dropdown>
          <button v-if="editOptions.length === 1 && editModel === null && adminEditMode === false" class="btn btn-sm btn-theme" @click="beginEditing(true)"><i class="fas fa-pencil mr-1" />Edit Private Descriptions</button>
          <div v-if="editModel" class="flex gap-1">
            <button class="btn btn-sm btn-theme-muted" @click="cancelEditing"><i class="fas fa-xmark mr-1" />Cancel</button>
            <button class="btn btn-sm btn-theme" @click="saveEditing"><i class="fas fa-save mr-1" />Save</button>
          </div>
        </div>
        <div class="flex gap-1 text-wrap text-sm">
          <i class="fas fa-book" />
          <textarea v-if="isAdminEditMode" class="form-control text-black w-full" v-model="schema.description"></textarea>
          <span v-else-if="schema.description !== null && schema.description.trim().length > 0">{{ schema.description }}</span>
          <span v-else class="italic text-gray-300">no system description</span>
        </div>
        <div class="text-wrap text-sm flex gap-1 items-center">
          <i class="fas fa-user-group" />
          <textarea v-if="editModel && !editModel.isEditingPrivate" class="form-control text-black w-full" v-model="schema.communityDescription"></textarea>
          <span v-else-if="schema.communityDescription !== null && schema.communityDescription.trim().length > 0">{{ schema.communityDescription }}</span>
          <span v-else class="italic text-gray-300">no community description</span>
        </div>
        <div class="flex gap-1 text-wrap text-sm">
          <i class="fas fa-user" />
          <textarea v-if="editModel && editModel.isEditingPrivate" class="form-control text-black w-full" v-model="schema.userDescription"></textarea>
          <span v-else-if="schema.userDescription !== null && schema.userDescription.trim().length > 0">{{ schema.userDescription }}</span>
          <span v-else class="italic text-gray-500">no private description</span>
        </div>
      </div>
      <div v-for="column in columns" :key="column.id" class="bg-white px-4 flex flex-col gap-1">
        <div class="flex flex-col md:flex-row justify-between">
          <div class="text-lg font-bold">{{ column.name }}</div>
          <div class="flex items-center">
            <div class="rounded-xl text-xs bg-theme-600 text-white px-2 py-1 w-20 text-center"><i class="fas fa-code mr-1" />{{ dataTypeFromRaw(column.type) }}</div>
          </div>
        </div>
        <div class="flex gap-1 text-wrap text-sm">
          <i class="fas fa-book" />
          <textarea v-if="isAdminEditMode" class="form-control text-black w-full" v-model="column.description"></textarea>
          <span v-else-if="column.description !== null && column.description.trim().length > 0">{{ column.description }}</span>
          <span v-else class="italic text-gray-500">no system description</span>
        </div>
        <div class="text-wrap text-sm flex gap-1 items-center">
          <i class="fas fa-user-group" />
          <textarea v-if="editModel && !editModel.isEditingPrivate" class="form-control text-black w-full" v-model="column.communityDescription"></textarea>
          <span v-else-if="column.communityDescription !== null && column.communityDescription.trim().length > 0">{{ column.communityDescription }}</span>
          <span v-else class="italic text-gray-500">no community description</span>
        </div>
        <div class="flex gap-1 text-wrap text-sm">
          <i class="fas fa-user" />
          <textarea v-if="editModel && editModel.isEditingPrivate" class="form-control text-black w-full" v-model="column.userDescription"></textarea>
          <span v-else-if="column.userDescription !== null && column.userDescription.trim().length > 0">{{ column.userDescription }}</span>
          <span v-else class="italic text-gray-500">no private description</span>
        </div>
        <hr class="mt-3" />
      </div>
    </div>
  </div>
</template>

<script>
import InlineDropdown from '@/components/InlineDropdown'
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import Loading from '@/components/Loading'
import RetryableError from '@/components/RetryableError'
import UserRolesMixin from '@/mixins/UserRolesMixin'
import _ from 'lodash'

export default {
  name: 'dataset-schema',
  mixins: [
    FullScreenLoadingMixin,
    UserRolesMixin
  ],
  components: {
    Loading,
    RetryableError,
    InlineDropdown
  },
  props: {
    datasetCategory: { type: String, required: true },
    datasetId: { type: String, required: true },
    isFullscreen: { type: Boolean, required: false, default: true },
    adminEditMode: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      schema: null,
      errorLoading: false,
      editModel: null
    }
  },
  computed: {
    hasInformationBanner () {
      return this.$store.state.informationBanner !== null
    },
    isAdminEditMode () {
      if (this.currentUser === null) return false
      return this.userRoles.includes('admin') && this.adminEditMode
    },
    hasCommunityEditRole () {
      if (this.currentUser === null) return false
      return this.userRoles.includes('schema-edit')
    },
    editOptions () {
      const self = this
      const options = []
      if (this.hasCommunityEditRole) {
        options.push({
          id: 'private',
          name: 'Community Descriptions',
          icon: 'fa-user-group',
          performAction: () => {
            self.beginEditing(false)
          }
        })
      }

      options.push({
        id: 'private',
        name: 'Private Descriptions',
        icon: 'fa-user',
        performAction: () => {
          self.beginEditing(true)
        }
      })
      return options
    },
    columns () {
      if (this.schema === null) return []
      return _.sortBy(this.schema.columns, [c => c.name.toLowerCase()])
    },
    schemaDataset () {
      return `${this.datasetCategory}/${this.datasetId}`
    }
  },
  watch: {
    schemaDataset () {
      this.fetchSchema()
    }
  },
  methods: {
    beginEditing (isEditingPrivate) {
      this.editModel = {
        isEditingPrivate,
        schemaBackup: JSON.parse(JSON.stringify(this.schema))
      }
    },
    async cancelEditing () {
      const descriptionType = this.editModel.isEditingPrivate ? 'private' : 'community'
      const result = await this.$swal.fire({
        title: 'Cancel Edits?',
        html: `Are you sure you want to cancel editing the ${descriptionType} descriptions for this dataset?<br><br>All changes will be lost and this action cannot be undone.`,
        icon: 'question',
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Yes, Cancel Editing',
        cancelButtonText: 'No, Continue Editing'
      })

      if (result.isConfirmed) {
        this.schema = this.editModel.schemaBackup
        this.editModel = null
      }
    },
    async saveEditing () {
      // editSchemaUserDescriptions
      const descriptionType = this.editModel.isEditingPrivate ? 'Private' : 'Community'
      try {
        this.showLoading(`Saving ${descriptionType} Descriptions...`, null)
        const payload = {
          category: this.schema.dataset.datasetCategory,
          datasetId: this.schema.dataset.datasetId,
          description: this.editModel.isEditingPrivate ? this.schema.userDescription : this.schema.communityDescription,
          columnDescriptions: this.schema.columns.map(c => {
            return { columnName: c.name, description: this.editModel.isEditingPrivate ? c.userDescription : c.communityDescription }
          })
        }
        const saveAction = this.editModel.isEditingPrivate ? 'editSchemaUserDescriptions' : 'editSchemaCommunityDescriptions'
        await this.$store.dispatch(saveAction, payload)
        await this.fetchSchema()
        this.editModel = null
        this.hideLoading()
        this.showSuccess(`${descriptionType} Descriptions Updated`)
      } catch (error) {
        console.error(`Error saving ${descriptionType.toLowerCase()} descriptions`, error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'Save Failed',
          text: `An unknown error occurred while saving the ${descriptionType.toLowerCase()} descriptions. Please try again.`,
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        })
      }
    },
    dataTypeFromRaw (rawType) {
      // corresponding backend definition in ColumnType
      switch (rawType) {
        case 0:
          return 'String'
        case 1:
          return 'Boolean'
        case 2:
          return 'Integer'
        case 3:
          return 'Decimal'
        case 4:
          return 'Epoch Timestamp in Seconds'
        case 5:
          return 'Epoch Timestamp in Milliseconds'
        default:
          return 'Unknown'
      }
    },
    async fetchSchema () {
      try {
        this.schema = null
        this.errorLoading = false
        const payload = { category: this.datasetCategory, datasetId: this.datasetId }
        const schemaPromise = this.$store.dispatch('fetchSchema', payload)
        const userDescriptionPromise = this.$store.dispatch('fetchSchemaUserDescriptions', payload)
        const responses = await Promise.all([schemaPromise, userDescriptionPromise])
        if (payload.category === this.datasetCategory && payload.datasetId === this.datasetId) {
          const schemaData = responses[0].data
          const userDescription = responses[1].data

          schemaData.userDescription = userDescription.description
          for (const column of schemaData.columns) {
            column.userDescription = _.find(userDescription.columns, ['name', column.name])?.description ?? ''
          }
          this.schema = schemaData
        }
      } catch (error) {
        console.error('Error loading dataset schema', error)
        this.errorLoading = true
      }
    }
  },
  mounted () {
    this.fetchSchema()
  }
}
</script>
