<template>
  <div class="flex w-full justify-center">
    <template v-if="showView">
      <retryable-error v-if="errorLoadingEntities" :text="`Error loading ${entityTypeDisplay.toLowerCase()}s.`" @retry="loadEntities"></retryable-error>
      <loading v-else-if="entities === null" :text="`Loading ${entityTypeDisplay.toLowerCase()}s...`"></loading>
      <div v-else class="flex w-full flex-col">
        <div class="flex px-1 gap-1">
          <div class="input-group-text w-42px"><i class="fas fa-filter" /></div>
          <input class="form-control group-text" type="text" :placeholder="`filter by ${filterColumns === null || filterColumns.length === 0 ? idColumn : filterColumns.join(', ')}`" v-model="filter" />
          <button class="btn btn-theme" href="#" @click="createEmptyEntity"><i class="fas fa-plus mr-1" />New</button>
          <button v-if="!disabledActions.includes('export')" class="btn btn-theme" @click="exportData(entities, `${entityType}-${currentDateString}.json`)"><i class="fas fa-file-export mr-1" />Export All</button>
          <button v-if="!disabledActions.includes('import')" class="btn btn-theme" href="#" @click="showImportModal = true"><i class="fas fa-file-import mr-1" />Import</button>
        </div>
        <entity-table
          :idColumn="idColumn"
          :actions="actions"
          :entityTypeDisplay="entityTypeDisplay"
          :columns="columns"
          :entities="filteredEntities">
        </entity-table>
      </div>
    </template>
    <div v-else>Page does not exist</div>

    <modal v-if="entityEditing !== null" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">{{ entityEditing.isNew ? 'New' : 'Edit' }} {{entityTypeDisplay}}</h1>
        <div v-if="editorHelpText"><strong>Note:</strong><span class="ml-1" v-html="editorHelpText"></span></div>
      </template>
      <template v-slot:body>
        <loading v-if="isSaving" :text="`Saving ${this.entityTypeDisplay}...`"></loading>
        <div v-show="!isSaving" ref="jsonEditor" class="h-1/2-vh"></div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" href="#" @click="entityEditing = null" :disabled="isSaving"><i class="fas fa-ban" /> Cancel</button>
        <button class="btn btn-theme" href="#" @click="saveEditor" :disabled="isSaving"><i class="fas fa-save" /> Save</button>
      </template>
    </modal>

    <modal v-if="showImportModal" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">Import {{entityTypeDisplay}}s</h1>
        <h3 v-if="importFileName">{{importFileName}}</h3>
      </template>
      <template v-slot:body>
        <loading v-if="processingImportFile" :text="`Processing ${entityTypeDisplay.toLowerCase()}s...`"></loading>
        <form v-else-if="entitiesToImport === null">
          <div class="border-dashed border-2 border-theme-500 h-100 hover:bg-theme-200 cursor-pointer p-2" @click="$refs.importInput.click()">
            <input
              type="file"
              class="hidden"
              accept="application/json"
              id="importInput"
              name="importInput"
              ref="importInput"
              :multiple="false"
              @drop="processImportFile($event.dataTransfer.files[0])"
              @change="processImportFile($event.target.files[0])"
            />
            <div class="flex flex-col cursor-pointer items-center text-theme-500">
              <i class="fas fa-upload  block text-xl mb-2"></i>
              <span class="text-sm">Click to Preview an Import</span>
              <span class="text-xs">2MB File Size Limit</span>
            </div>
          </div>
        </form>
        <template v-else>
          <div><strong>Note:</strong> When importing, you can merge or replace the existing {{ entityTypeDisplay }}s. Replacing will delete all current items before adding these. Merging will not delete anything on the server and any item with the same {{ idColumn }} will be updated with the new import.</div>
          <entity-table
            :id-column="idColumn"
            :actions="[]"
            :entity-type-display="entityTypeDisplay"
            :columns="columns"
            :entities="entitiesToImport">
          </entity-table>
        </template>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" style="margin-right:auto;" href="#" @click="showImportModal = false"><i class="fas fa-ban" /> Cancel</button>
        <button class="btn btn-theme" href="#" @click="importEntities(true)" :disabled="entitiesToImport === null || processingImportFile"><i class="fas fa-file-import" /> Import and Replace</button>
        <button class="btn btn-theme" href="#" @click="importEntities(false)" :disabled="entitiesToImport === null || processingImportFile"><i class="fas fa-file-import" /> Import and Merge</button>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import EntityTable from '@/components/admin/EntityTable'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import RetryableError from '@/components/RetryableError'
import UserRolesMixin from '@/mixins/UserRolesMixin'
import JSONEditor from 'jsoneditor/dist/jsoneditor-minimalist.js'
import 'jsoneditor/dist/jsoneditor.min.css'

const MAX_FILE_SIZE = 2000000 // 2 MB

export default {
  name: 'entity-editor',
  components: {
    EntityTable,
    Loading,
    Modal,
    RetryableError
  },
  mixins: [UserRolesMixin],
  emits: ['new-entity', 'copy-entity', 'edit-entity'],
  props: {
    idColumn: { type: String, required: false, default: 'id' },
    sortColumn: { type: String, required: false, default: null },
    isSortAscending: { type: Boolean, required: false, default: true },
    filterColumns: { type: Array, required: false, default: null },
    customEditor: { type: Boolean, required: false, default: false },
    disabledActions: { type: Array, required: false, default: () => { return [] } },
    performClear: { type: Function, required: false, default: () => {} },
    editorHelpText: { type: String, required: false, default: null },
    newEntitySchema: { type: Object, required: false, default: null },
    entityTypeDisplay: { type: String, required: true },
    entityType: { type: String, required: true },
    columns: { type: Array, required: true },
    exportNormalizer: { type: Function, required: false, default: (entities) => { return entities } },
    customActions: { type: Array, required: false, default: () => { return [] } }
  },
  data () {
    return {
      entities: null,
      filter: '',
      entityEditing: null,
      isSaving: false,
      showImportModal: false,
      processingImportFile: false,
      entitiesToImport: null,
      importFileName: '',
      errorLoadingEntities: false
    }
  },
  async mounted () {
    this.loadEntities()
  },
  computed: {
    actions () {
      const standardActions = [
        { name: 'edit', display: 'Edit', icon: 'fa-pencil', perform: entity => this.openEditor(entity, false) },
        { name: 'copy', display: 'Copy', icon: 'fa-copy', perform: entity => this.openEditor(entity, true) },
        { name: 'export', display: 'Export', icon: 'fa-file-export', perform: entity => this.exportData(entity, `${entity[this.idColumn]}-${this.currentDateString}.json`) },
        { name: 'disable', display: 'Toggle Enabled', icon: 'fa-toggle-on', perform: entity => this.toggleEntityEnabledState(entity) },
        { name: 'delete', display: 'Delete', icon: 'fa-trash', perform: entity => this.setEntityToDelete(entity) }
      ]

      return _.sortBy(this.customActions.concat(standardActions), 'display').filter(a => !this.disabledActions.includes(a.name))
    },
    showView () {
      if (this.currentUser === null) return false
      return this.userRoles.includes('admin')
    },
    filteredEntities () {
      if (this.entities === null) return []

      const targetColumns = (this.filterColumns === null || this.filterColumns.length === 0) ? [this.idColumn] : this.filterColumns

      const filtered = this.entities.filter(entity => {
        return _.some(targetColumns, column => entity[column]?.toLowerCase().includes(this.filter.toLowerCase()))
      })

      const sortColumn = this.sortColumn === null ? this.idColumn : this.sortColumn
      return _.sortBy(filtered, entity => {
        if (this.isSortAscending) {
          return entity[sortColumn].toString().toLowerCase()
        }
        return -entity[sortColumn].toString().toLowerCase()
      })
    },
    currentDateString () {
      const now = new Date()
      return `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}`
    }
  },
  watch: {
    showImportModal () {
      this.entitiesToImport = null
      this.importFileName = ''
    }
  },
  methods: {
    async loadEntities () {
      try {
        this.errorLoadingEntities = false
        this.entities = null
        const response = await this.$store.dispatch('fetchAdminEntities', this.entityType)
        this.entities = response.data
      } catch (error) {
        console.error('Error loading the entities', error)
        this.errorLoadingEntities = true
      }
    },
    exportData (data, filename) {
      let exportData = data
      if (!Array.isArray(exportData)) {
        exportData = [data]
      }
      exportData = this.exportNormalizer(exportData)
      const url = this.createExportUrl(exportData)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // revoke 2 seconds later to let the download finish
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 2000)
    },
    createExportUrl (entities) {
      const clonedEntities = JSON.parse(JSON.stringify(entities))
      for (const entity of clonedEntities) {
        this.scrubNestedIds(entity)
      }
      const downloadBlob = new Blob([JSON.stringify(clonedEntities, null, 2)], { type: 'application/json' })
      return URL.createObjectURL(downloadBlob)
    },
    async processImportFile (file) {
      if (file.size > MAX_FILE_SIZE) {
        this.$swal({
          icon: 'error',
          title: 'Large File',
          text: `The file "${file.name}" is too large, files must be under 2MB.`
        })
        return
      }
      try {
        this.processingImportFile = true
        this.importFileName = file.name
        const fileText = await file.text()
        this.entitiesToImport = JSON.parse(fileText)
      } catch (error) {
        console.error('Error parsing import file.', error)
        this.$swal({
          icon: 'error',
          title: 'Import Failed',
          text: `An error occurred while reading the file "${file.name}. Ensure the file is valid and try again."`
        })
      } finally {
        this.processingImportFile = false
      }
    },
    async importEntities (isReplace) {
      if (isReplace) {
        const result = await this.$swal({
          title: 'Are you sure?',
          text: 'This will replace all existing entities with the new data. This action cannot be undone.',
          icon: 'question',
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'Yes, replace it!',
          backdrop: true
        })

        if (!result.isConfirmed) {
          return
        }
      }
      try {
        this.processingImportFile = true
        const response = await this.$store.dispatch('importAdminEntities', { type: this.entityType, entities: this.entitiesToImport, isReplace })
        this.entities = response.data
        this.showImportModal = false
        this.performClear()
        this.$swal({
          icon: 'success',
          title: 'Import Successful',
          allowOutsideClick: true,
          allowEscapeKey: false,
          showConfirmButton: false,
          timer: 2000,
          backdrop: true
        })
      } catch (error) {
        console.error('Error importing admin entity data.', error)
        const serverErrors = error?.response?.data?._embedded?.errors?.map(e => e.message) ?? []
        this.$swal({
          icon: 'error',
          title: 'Import Failed',
          text: `Error importing ${this.entityTypeDisplay}s.\n\n${serverErrors.join('/n/n')}\n\nEnsure the file is valid and check the browser's console for errors.`
        })
      } finally {
        this.processingImportFile = false
      }
    },
    async createEmptyEntity () {
      if (this.customEditor) {
        this.$emit('new-entity')
        return
      }

      let emptyEntity = { }
      if (this.newEntitySchema !== null) {
        emptyEntity = JSON.parse(JSON.stringify(this.newEntitySchema))
      } else {
        emptyEntity[this.idColumn] = null
      }
      this.entityEditing = {
        isNew: true,
        editor: null
      }

      await this.$nextTick()
      const container = this.$refs.jsonEditor
      this.entityEditing.editor = new JSONEditor(container, { modes: ['text', 'tree'], enableTransform: false, enableSort: false }, emptyEntity)
    },
    scrubNestedIds (obj, isRoot = true) {
      if (obj === null) return

      // don't delete any id fields at the root
      if (isRoot) {
        for (const key of Object.keys(obj)) {
          this.scrubNestedIds(obj[key], false)
        }
        return
      }

      if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
          for (const item of obj) {
            this.scrubNestedIds(item, false)
          }
        } else {
          delete obj.id
          for (const key of Object.keys(obj)) {
            this.scrubNestedIds(obj[key], false)
          }
        }
      }
    },
    async openEditor (entity, isNew) {
      const clonedContent = JSON.parse(JSON.stringify(entity))

      if (this.customEditor) {
        if (isNew) {
          this.$emit('copy-entity', clonedContent)
        } else {
          this.$emit('edit-entity', clonedContent)
        }
        return
      }

      if (isNew === true) {
        clonedContent[this.idColumn] = null
      }
      this.scrubNestedIds(clonedContent)
      this.entityEditing = {
        isNew,
        editor: null
      }

      await this.$nextTick()
      const container = this.$refs.jsonEditor
      this.entityEditing.editor = new JSONEditor(container, { modes: ['text', 'tree'], enableTransform: false, enableSort: false }, clonedContent)
    },
    async setEntityToDelete (entity) {
      const entityName = this.filterColumns === null || this.filterColumns.length === 0 ? entity[this.idColumn] : entity[this.filterColumns[0]]
      const result = await this.$swal.fire({
        title: `Delete ${this.entityTypeDisplay}?`,
        html: `Are you sure you want to delete the ${this.entityTypeDisplay.toLowerCase()} <strong>${entityName}</strong>? <br><br>This action cannot be undone.`,
        icon: 'question',
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Yes, delete it!',
        backdrop: true
      })
      if (result.isConfirmed) {
        await this.deleteEntity(entity)
      }
    },
    async saveEditor () {
      if (this.entityEditing === null) return

      try {
        const entityToSave = this.entityEditing.editor.get()
        if (typeof entityToSave[this.idColumn] !== 'string' || entityToSave[this.idColumn] === null) {
          this.$swal({
            icon: 'error',
            title: 'Invalid ID',
            text: 'A valid String ID must be given.'
          })
          return
        }

        this.isSaving = true
        if (this.entityEditing.isNew) {
          const response = await this.$store.dispatch('saveAdminEntityNew', { type: this.entityType, entity: entityToSave })
          this.entities.push(response.data)
          this.entityEditing = null
          this.$swal({
            icon: 'success',
            title: 'Creation Successful',
            allowOutsideClick: true,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 2000,
            backdrop: true
          })
        } else {
          const index = this.entities.findIndex(entity => entity[this.idColumn] === entityToSave[this.idColumn])
          if (index > -1) {
            const response = await this.$store.dispatch('saveAdminEntityEdit', { type: this.entityType, entity: entityToSave })
            this.entities[index] = response.data
            this.entityEditing = null
            this.$swal({
              icon: 'success',
              title: 'Edit Successful',
              html: `${this.entityTypeDisplay} details have been updated`,
              allowOutsideClick: true,
              allowEscapeKey: false,
              showConfirmButton: false,
              timer: 1200,
              backdrop: true
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'ID Not Found',
              text: `The given ID for the ${this.entityTypeDisplay} you are editing does not exist.`
            })
          }
        }
      } catch (error) {
        console.error(`Error saving ${this.entityTypeDisplay} in editor`, error)
        const serverErrors = error?.response?.data?._embedded?.errors?.map(e => e.message) ?? []
        this.$swal({
          icon: 'error',
          title: 'Failed to Save',
          text: `Error saving the ${this.entityTypeDisplay}.\n\n${serverErrors.join('/n/n')}\n\nEnsure the JSON is valid and check the browser's console for errors.`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true
        })
      } finally {
        this.isSaving = false
        this.performClear()
      }
    },
    async toggleEntityEnabledState (entity) {
      const setEnabled = !entity.enabled

      try {
        if (setEnabled) {
          await this.$store.dispatch('setAdminEntityEnabled', { type: this.entityType, id: entity[this.idColumn] })
          this.$swal({
            icon: 'success',
            title: 'Enabled Successfully',
            html: `${this.entityTypeDisplay} is now enabled`,
            allowOutsideClick: true,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 1200,
            backdrop: true
          })
        } else {
          await this.$store.dispatch('setAdminEntityDisabled', { type: this.entityType, id: entity[this.idColumn] })
          this.$swal({
            icon: 'success',
            iconColor: 'red',
            title: 'Disabled Successfully',
            html: `${this.entityTypeDisplay} is now disabled`,
            allowOutsideClick: true,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 1200,
            backdrop: true
          })
        }
        entity.enabled = setEnabled
        this.performClear()
      } catch (error) {
        console.error(`Error toggling ${this.entityTypeDisplay} ${entity[this.idColumn]} enabled state`, error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Enable/Disable',
          html: `${this.entityTypeDisplay} did not change state<br><br><strong>Error Details</strong><br>${error.message}`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true
        })
      }
    },
    async deleteEntity (entity) {
      try {
        await this.$store.dispatch('deleteAdminEntity', { type: this.entityType, id: entity[this.idColumn] })
        this.entities = this.entities.filter(d => d[this.idColumn] !== entity[this.idColumn])
        this.$swal({
          icon: 'success',
          title: 'Deletion Successful',
          allowOutsideClick: true,
          allowEscapeKey: false,
          showConfirmButton: false,
          timer: 1200,
          backdrop: true
        })

        this.performClear()
      } catch (error) {
        console.error(`Error deleting ${this.entityTypeDisplay} ${entity[this.idColumn]}`, error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Delete',
          html: `${this.entityTypeDisplay} was not removed<br><br><strong>Error Details</strong><br>${error.message}`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true
        })
      }
    }
  }
}
</script>
