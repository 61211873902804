<template>
  <div>
    <entity-editor
      entity-type-display="Dataset"
      entity-type="datasets/internal"
      :columns="datasetColumns"
      :perform-clear="clearDatasets"
      :custom-actions="customActions">
    </entity-editor>
    <modal v-if="datasetSchemaEdit" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">Edit Schema for {{ datasetSchemaEdit.id }}</h1>
      </template>
      <template v-slot:body>
        <dataset-schema ref="datasetSchema" :dataset-category="datasetSchemaEdit.category" :dataset-id="datasetSchemaEdit.id" :is-fullscreen="false" :admin-edit-mode="true"></dataset-schema>
      </template>
      <template v-slot:footer>
        <div class="flex gap-1">
          <button class="btn btn-sm btn-theme-muted" @click="cancelSchemaEdit"><i class="fas fa-xmark mr-1" />Cancel</button>
          <button class="btn btn-sm btn-theme" @click="saveSchema"><i class="fas fa-save mr-1" />Save</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import EntityEditor from '@/components/admin/EntityEditor'
import Modal from '@/components/Modal'
import DatasetSchema from '@/views/DatasetSchema'

export default {
  name: 'internal-dataset-settings',
  mixins: [
    FullScreenLoadingMixin
  ],
  components: {
    EntityEditor,
    Modal,
    DatasetSchema
  },
  data () {
    return {
      datasetSchemaEdit: null,
      datasetColumns: [
        { field: 'category', display: 'Category' },
        { field: 'schema', display: 'Schema' }
      ],
      customActions: [
        { name: 'schemaUpdate', display: 'Run Schema Update', icon: 'fa-database', perform: this.runSchemaUpdate },
        { name: 'updateSchemaDescriptions', display: 'Update Schema Descriptions', icon: 'fa-file-pen', perform: this.beginSchemaDescriptionEdit }
      ]
    }
  },
  computed: {
    sourceDescriptions () {
      return this.$store.state.sourceDescriptions
    },
    datasetSearches () {
      return this.$store.state.datasetSearches
    },
    selectedDataset () {
      return this.$store.state.selectedDataset
    }
  },
  methods: {
    async cancelSchemaEdit () {
      const result = await this.$swal.fire({
        title: 'Cancel Edits?',
        html: 'Are you sure you want to cancel editing the system descriptions for this dataset?<br><br>All changes will be lost and this action cannot be undone.',
        icon: 'question',
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Yes, Cancel Editing',
        cancelButtonText: 'No, Continue Editing'
      })

      if (result.isConfirmed) {
        this.datasetSchemaEdit = null
      }
    },
    async saveSchema () {
      try {
        this.showLoading('Saving System Descriptions...', null)
        const schema = this.$refs.datasetSchema.schema
        const payload = {
          datasetId: this.datasetSchemaEdit.id,
          description: schema.description,
          columnDescriptions: schema.columns.map(c => {
            return { columnName: c.name, description: c.description }
          })
        }
        await this.$store.dispatch('editSchemaSystemDescriptions', payload)
        this.datasetSchemaEdit = null
        this.hideLoading()
        this.showSuccess('System Descriptions Updated')
      } catch (error) {
        console.error('Error saving system descriptions', error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'Save Failed',
          text: 'An unknown error occurred while saving the system descriptions. Please try again.',
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        })
      }
    },
    beginSchemaDescriptionEdit (dataset) {
      this.datasetSchemaEdit = JSON.parse(JSON.stringify(dataset))
    },
    async runSchemaUpdate (dataset) {
      try {
        this.showLoading('Running Dataset Schema Update Job...', null)
        await this.$store.dispatch('runInternalDatasetSchemaUpdateJob', dataset.id)
        this.hideLoading()
        this.showSuccess('Dataset Schema Updated Succcessfully')
      } catch (error) {
        console.error('An error occurred while running the schema udpate job', error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'Schema Update Job Failed',
          text: 'An unknown error occurred while running the schema update job. Please try again.',
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        })
      }
    },
    clearDatasets () {
      // clear all dataset data and search data
      this.$store.commit('setSelectedDataset', null)
      this.$store.commit('setUnifiedDatasetCategories', null)
      this.$store.commit('clearSourceDescriptions')
      this.$store.commit('clearDatasetSearches')
    }
  }
}
</script>
