<template>
  <div>
    <router-view></router-view>
    <div v-if="isRootSchemaDiscovery" class="w-full flex flex-col min-h-screen">
      <div :class="['w-full flex flex-col gap-1 bg-theme-500 text-white p-2 sticky', { 'top-6': hasInformationBanner }, { 'top-0': !hasInformationBanner }]">
        <div class="flex flex-col md:flex-row w-full justify-between">
          <div class="font-extrabold text-2xl">Data Catalog</div>
          <div v-if="!isLoading" class="font-bold text-md">{{ filteredSchemas.length }} Dataset{{ filteredSchemas.length !== 1 ? 's' : '' }}</div>
        </div>
        <div class="flex flex-col lg:flex-row w-full gap-4">
          <div class="flex flex-col flex-1">
            <div class="font-bold text-sm">Dataset Name</div>
            <input type="text" class="form-control text-black" v-model="datasetName" placeholder="filter by dataset name" />
          </div>
          <div class="flex flex-col flex-1">
            <div class="font-bold text-sm">Column Name</div>
            <input type="text" class="form-control text-black" v-model="columnName" placeholder="fitler by datasets with a column" />
          </div>
          <div class="flex flex-col">
            <div class="font-bold text-sm">Contains Location</div>
            <v-select :options="['Any', 'Yes', 'No']" v-model="locationData" placeholder="Contains Location Data" :append-to-body="true" :clearable="false" class="bg-white"></v-select>
          </div>
          <div class="flex flex-col text-sm">
            <div class="font-bold">Supports Map View</div>
            <v-select :options="['Any', 'Yes', 'No']" v-model="mapView" placeholder="Contains Location Data" :append-to-body="true" :clearable="false" class="bg-white"></v-select>
          </div>
        </div>
      </div>

      <retryable-error v-if="errorLoading" text="Error Loading Dataset Schemas" @retry="searchSchemas"></retryable-error>
      <loading v-else-if="isLoading"  text="Loading..."></loading>
      <div v-else class="flex flex-col w-full">
        <div v-if="filteredSchemas.length === 0" class="w-full text-center text-gray-500 mt-3">no matching datasets</div>
        <router-link v-for="schema in filteredSchemas" :key="schema.id" :to="{ name: 'DatasetSchema', params: { datasetCategory: schema.dataset.datasetCategory, datasetId: schema.dataset.datasetId } }" class="flex flex-col gap-1 px-2 cursor-pointer hover:bg-theme-100">
          <div class="flex flex-col md:flex-row justify-between">
            <h1 class="text-2xl font-extrabold">{{ schema.dataset.datasetId }}</h1>
            <div class="flex gap-2 text-xs items-center" v-if="schema.containsLocationData || schema.supportsMapResults">
              <div v-if="schema.supportsMapResults" class="rounded-xl bg-orange-600 text-white px-2 py-1"><i class="fas fa-globe mr-1" />Map View</div>
              <div v-if="schema.containsLocationData" class="rounded-xl bg-green-700 text-white px-2 py-1"><i class="fas fa-location-dot mr-1" />Location</div>
            </div>
          </div>
          <div class="flex gap-1 text-wrap text-sm">
            <i class="fas fa-book" />
            <span v-if="schema.description !== null && schema.description.trim().length > 0">{{ schema.description }}</span>
            <span v-else class="italic text-gray-500">no system description</span>
          </div>
          <div class="flex gap-1 text-wrap text-sm">
            <i class="fas fa-user-group" />
            <span v-if="schema.communityDescription !== null && schema.communityDescription.trim().length > 0">{{ schema.communityDescription }}</span>
            <span v-else class="italic text-gray-500">no community description</span>
          </div>
          <hr class="mt-3" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import RetryableError from '@/components/RetryableError'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'schema-discovery',
  components: {
    Loading,
    RetryableError
  },
  props: {
  },
  data () {
    return {
      schemas: null,
      errorLoading: false,
      datasetName: '',
      columnName: '',
      locationData: 'Any',
      mapView: 'Any',
      latestSearchId: 0
    }
  },
  computed: {
    isRootSchemaDiscovery () {
      return this.$route.name === 'SchemaDiscovery'
    },
    hasInformationBanner () {
      return this.$store.state.informationBanner !== null
    },
    filteredSchemas () {
      if (this.schemas === null) return []
      const filtered = this.schemas.filter(s => s.dataset.datasetId.toLowerCase().includes(this.datasetName.toLowerCase()))
      return _.sortBy(filtered, [s => s.dataset.datasetId.toLowerCase()])
    },
    isLoading () {
      return this.schemas === null && !this.errorLoading
    },
    searchPayload () {
      return {
        columnName: this.columnName,
        containsLocationData: this.locationData === 'Any' ? null : (this.locationData === 'Yes'),
        supportsMapResults: this.mapView === 'Any' ? null : (this.mapView === 'Yes')
      }
    }
  },
  watch: {
    searchPayload () {
      this.searchSchemas()
    }
  },
  methods: {
    searchSchemas: _.debounce(async function () {
      try {
        this.schemas = null
        this.errorLoading = false

        const searchId = uuidv4()
        this.latestSearchId = searchId
        const response = await this.$store.dispatch('findSchemas', this.searchPayload)
        if (searchId === this.latestSearchId) {
          // don't show results if the search has run again while we waited on this call to finish
          this.schemas = response.data
        }
      } catch (error) {
        console.log('error searching schemas', error)
        this.errorLoading = true
      }
    }, 250)
  },
  mounted () {
    this.searchSchemas()
  }
}
</script>
