<!-- taken nearly straight from https://v3.vuejs.org/examples/modal.html !-->
<template>
  <div :class="['modal-mask h-screen w-screen flex items-center justify-center', { 'top-6': hasInformationBanner }, { 'top-0': !hasInformationBanner }]">
    <div :class="`modal-container rounded w-full modal-padding px-1 lg:px-3 ${largeWidthClass} ${bgClass}`">
      <div class="modal-header block">
        <slot name="header">
        </slot>
      </div>

      <div class="modal-body modal-padding px-1 lg:px-3">
        <slot name="body">
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: {
    largeWidthClass: { type: String, required: false, default: 'lg:w-1/2' },
    bgClass: { type: String, required: false, default: 'bg-white' }
  },
  computed: {
    hasInformationBanner () {
      return this.$store.state.informationBanner !== null
    }
  },
  mounted () {
    document.body.classList.add('modal-open')
  },
  unmounted () {
    document.body.classList.remove('modal-open')
  }
}
</script>

<style lang="less" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-header {
  @apply items-center justify-start p-5 border-b border-gray-200;
  max-height: 20vh;
  overflow-y: auto;
}

.modal-body {
  max-height: 50vh;
  overflow-y: auto;
}

.modal-footer {
  @apply flex flex-wrap items-center justify-end p-5 border-t border-gray-200;
  max-height: 20vh;
  overflow-y: auto;
  :deep(> *) {
    @apply m-1;
  }
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
