<template>
  <unified-dataset-selector
      v-model="selectedDatasets"
      :allow-multiple="true"
      @datasets-loaded="onDatasetsLoaded">
  </unified-dataset-selector>
</template>

<script>
import UnifiedDatasetSelector from '@/components/UnifiedDatasetSelector'

export default {
  name: 'simple-multi-dataset-selector',
  components: {
    UnifiedDatasetSelector
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Array, required: true }
  },
  data () {
    return {
      selectedDatasets: null
    }
  },
  computed: {
    apiKeySelectedDatasets () {
      if (this.selectedDatasets === null) return null

      return this.selectedDatasets.map(dataset => {
        return {
          datasetCategory: dataset.model.category.id,
          datasetId: dataset.model.dataset.id
        }
      })
    }
  },
  watch: {
    apiKeySelectedDatasets () {
      this.$emit('update:modelValue', this.apiKeySelectedDatasets)
    }
  },
  methods: {
    onDatasetsLoaded (unifiedDatasets) {
      const selectedDatasets = []
      for (const dataset of this.modelValue) {
        for (const selectorDataset of unifiedDatasets) {
          if (typeof selectorDataset.model === 'undefined') continue
          if (dataset.datasetCategory === selectorDataset.model.category.id && dataset.datasetId === selectorDataset.model.dataset.id) {
            selectedDatasets.push(selectorDataset)
            break
          }
        }
      }
      this.selectedDatasets = selectedDatasets
    }
  }
}
</script>
