<template>
  <VDropdown v-model:shown="showDropDown" :triggers="[]" :disabled="disabled">
    <div v-if="$slots.triggerButton" @click="showDropDown = !showDropDown" :class="[{ 'cursor-not-allowed': disabled }, { 'cursor-pointer': !disabled }]">
      <slot name="triggerButton"></slot>
    </div>
    <button v-else :class="[{'cursor-pointer': !disabled}, {'cursor-not-allowed': disabled}, {'btn btn-theme btn-sm form-control': dropdownStyle === 'button'}, {'hover:text-theme-200': dropdownStyle === 'text' && !disabled}]" @click="showDropDown = !showDropDown" :disabled="disabled">
      {{ title }}
      <i :class="['ml-1 fas', {'fa-chevron-up': showDropDown}, {'fa-chevron-down': !showDropDown}]" />
    </button>
    <template #popper>
      <div v-for="option in options" :key="option.id" @click="selectOption(option)" :class="['last:border-0 flex border-b border-black items-center p-1', {'hover:bg-theme-400 text-black hover:text-white cursor-pointer': !isOptionDisabled(option)}, {'text-gray-400 cursor-not-allowed hover:text-gray-400': isOptionDisabled(option)} ]" :disabled="isOptionDisabled(option)">
        <i v-if="option.icon" :class="`mr-1 fas ${option.icon}`"></i> {{option.name}}
      </div>
    </template>
  </VDropdown>
</template>

<script>

export default {
  name: 'inline-dropdown',
  props: {
    options: { type: Array, required: true },
    buttonTitle: { type: String, required: false, default: '' },
    dropdownStyle: { type: String, required: false, default: 'button' },
    updateButtonTitle: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      showDropDown: false,
      title: this.buttonTitle
    }
  },
  methods: {
    isOptionDisabled (option) {
      if (typeof option.disabled === 'undefined') return false
      return option.disabled === true
    },
    selectOption (option) {
      if (this.disabled) return
      if (this.isOptionDisabled(option)) return
      option.performAction()
      this.showDropDown = false
      if (this.updateButtonTitle === true) {
        this.title = option.name
      }
    }
  }
}
</script>
