<template>
  <div class="flex w-full gap-4 text-theme-500">
    <div v-for="tab in tabs" :key="tab.id" :class="[{ 'cursor-pointer': !disabled }, { 'cursor-not-allowed text-gray-500': disabled }, { 'font-bold underline': tab.id === modelValue.id }]" @click="onTabClicked(tab)">{{ tab.label }}</div>
  </div>
</template>

<script>
export default {
  name: 'tab-selector',
  props: {
    modelValue: { type: Object, required: true },
    tabs: { type: Array, required: true },
    disabled: { type: Boolean, required: false, default: false }
  },
  emits: ['update:modelValue'],
  methods: {
    onTabClicked (tab) {
      if (this.disabled) return
      this.$emit('update:modelValue', tab)
    }
  }
}
</script>
