<template>
  <template v-for="route in routes" :key="route.name">
    <router-link v-if="showRoute(route)" :to="{name: route.name, query: route.getQuery()}" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
      <i :class="['fas mr-2', route.icon]"></i>
      <span class="text-sm">{{route.display}}</span>
    </router-link>
    <div class="flex flex-col gap-1 -mt-2 mr-2">
      <VMenu v-for="childRoute in route.getChildRoutes()" :key="childRoute.id" :delay="{ show: 200, hide: 0 }">
        <router-link :to="childRoute.routeTo" class="ml-3 p-1 rounded flex flex-col w-full text-xs hover:no-underline hover:text-white hover:bg-theme-300">
          <span class="truncate">{{ childRoute.title }}</span>
          <span>{{ childRoute.subtitle }}</span>
        </router-link>

        <template #popper>
          <component :is="childRoute.popoverComponent" :value="childRoute.popoverModel"></component>
        </template>
      </VMenu>
    </div>
  </template>
</template>

<script>
import UserRolesMixin from '@/mixins/UserRolesMixin'
import SearchPreview from '@/components/SearchPreview'
import moment from 'moment'

export default {
  name: 'search-child-menu',
  mixins: [UserRolesMixin],
  components: {
    SearchPreview
  },
  data () {
    return {
      routes: [
        {
          name: 'UnifiedDatasets',
          icon: 'fa-search',
          display: 'Datasets',
          getQuery: () => { return null },
          passesRoleCheck: () => { return true },
          getChildRoutes: () => { return this.datasetSearches }
        },
        {
          name: 'Filesets',
          icon: 'fa-folder',
          display: 'Filesets',
          getQuery: () => { return this.filesetQuery },
          passesRoleCheck: () => { return true },
          getChildRoutes: () => { return [] }
        },
        {
          name: 'SchemaDiscovery',
          icon: 'fa-database',
          display: 'Data Catalog',
          getQuery: () => { return null },
          passesRoleCheck: () => { return true },
          getChildRoutes: () => { return [] }
        }
      ]
    }
  },
  computed: {
    externalSearchPages () {
      return this.$store.state.externalPages.filter(page => page.navBar === 'search')
    },
    filesetQuery () {
      return this.$store.state.filesetQueryParams
    },
    disabledLinks () {
      return this.$store.state.disabledLinks
    },
    datasetSearches () {
      return this.$store.state.datasetSearches.map(search => {
        return {
          id: search.id,
          title: search.datasetDescription.datasetId,
          subtitle: moment(search.timestamp).format('h:mm a'),
          routeTo: {
            name: 'SearchResults',
            params: { searchId: search.id }
          },
          popoverComponent: 'search-preview',
          popoverModel: search
        }
      })
    }
  },
  methods: {
    showRoute (route) {
      return !this.disabledLinks.includes(route.name) && route.passesRoleCheck()
    }
  },
  mounted () {
    for (const externalPage of this.externalSearchPages) {
      this.routes.push({
        name: externalPage.name,
        icon: externalPage.faIcon,
        display: externalPage.displayName,
        getQuery: () => { return null },
        passesRoleCheck: () => { return this.checkRole(externalPage.role) },
        getChildRoutes: () => { return [] }
      })
    }
  }
}
</script>
