<template>
  <table class="w-full mt-2">
    <thead class="w-full text-white sticky top-0 bg-theme-400 z-999">
      <tr>
        <th class="px-1 border-r border-black">{{idColumn}}</th>
        <th v-for="column in columns" :key="column.field" class="px-1 border-r border-black">
          {{ column.display }}
        </th>
        <th v-if="showActions" class="px-1"></th>
      </tr>
    </thead>
    <tbody class="w-full overflow-y-auto">
      <tr v-for="(entity, idx) in entities" :key="entity[idColumn]" :title="entity[idColumn]" :class="['py-1 h-16 w-full hover:bg-theme-200 max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
        <td class="break-all px-1 border-r border-black"><i v-if="canBeDisabled" :class="['fas fa-circle mr-2', { 'text-emerald-600': entity.enabled }, { 'text-rose-600': !entity.enabled }]" :title="entity.enabled ? 'enabled' : 'disabled'"></i>{{entity[idColumn]}}</td>
        <td v-for="column in columns" :key="column.field" class="break-all px-1 border-r border-black">
          <template v-if="column.renderer">
            {{ column.renderer(entity[column.field]) }}
          </template>
          <template v-else>
            {{ entity[column.field] }}
          </template>
        </td>
        <td v-if="showActions" class="break-all px-1 text-center">
          <inline-dropdown :options="entityActions">
            <template v-slot:triggerButton>
              <i class="fas fa-ellipsis text-theme-400 hover:text-theme-100 cursor-pointer text-2xl" title="actions" @click="entityAction = entity"></i>
            </template>
          </inline-dropdown>
        </td>
      </tr>
      <tr v-if="entities.length === 0">
        <td class="text-center text-gray-700" :colspan="columns.length + 1">no matching {{entityTypeDisplay.toLowerCase()}}s</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import InlineDropdown from '@/components/InlineDropdown'

export default {
  name: 'entity-table',
  components: {
    InlineDropdown
  },
  props: {
    idColumn: { type: String, required: true },
    actions: { type: Array, required: true },
    entityTypeDisplay: { type: String, required: true },
    columns: { type: Array, required: true },
    entities: { type: Array, required: true }
  },
  data () {
    return {
      entityAction: null
    }
  },
  computed: {
    canBeDisabled () {
      return this.actions.map(a => a.name).includes('disable')
    },
    showActions () {
      return this.actions.length > 0
    },
    entityActions () {
      const self = this
      return this.actions.map(a => {
        return {
          id: a.name,
          name: a.display,
          icon: a.icon,
          performAction: () => {
            a.perform(self.entityAction)
            self.entityAction = null
          }
        }
      })
    }
  }
}
</script>
